import "./App.scss";


import React, { useState, useEffect } from 'react';
// IMPORT COMPONENTS
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Route, Routes } from "react-router-dom";
// IMPORT PAGES
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Contact from './pages/Contact';

import { ThemeContext } from './pages/ThemeContext';

function App() {
  const URL = 'http://localhost:4000/';
  const [isDark, setIsDark] = useState(false);

  const [inView, setInView] = useState(false);

  const toggleTheme = () => {
    setIsDark(!isDark);
  }
  


  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
    <div className={`App ${isDark ? 'dark' : 'light'}`}>
      <Header />
      <div id="home">
        <Home />
      </div>
      <hr />
      <div id="about">
        <About URL={URL} />
      </div>
      <hr />
      <div id="projects">
        <Projects URL={URL} />
      </div>
      <hr />
      <div id="contact">
        <Contact URL={URL} />
      </div>
      <hr />
      <Footer />
    </div>
    </ThemeContext.Provider>
  );
}

export default App;
