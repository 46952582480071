import "./Header.scss";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';

function Header(props) {
  const navClassName = 'header-nav';

  return (
    <header>
      <nav className={navClassName}>
        <label htmlFor="hamburger">&#9776;</label>
        <input type="checkbox" id="hamburger"/>
        <div id="nav-items">
          <ScrollLink to="home" smooth={true} duration={500}>
            <div>HOME</div>
          </ScrollLink>
          <ScrollLink to="about" smooth={true} duration={500}>
            <div>ABOUT</div>
          </ScrollLink>
          <ScrollLink to="projects" smooth={true} duration={500}>
            <div>PROJECTS</div>
          </ScrollLink>
          <ScrollLink to="contact" smooth={true} duration={500}>
            <div>CONTACT</div>
          </ScrollLink>
        </div>
      </nav>
    </header>
  );
}

export default Header;
