import "./Home.scss";
import profileImage from '../Image/test.jpg';
import ParticlesBg from "particles-bg";

import React, { useContext } from 'react';
import { ThemeContext } from '../pages/ThemeContext';
import lightModeImg from '../Image/light.png';
import darkModeImg from '../Image/dark.png';


function Home(props) {
  const { isDark, toggleTheme } = useContext(ThemeContext);

  return (
    <div className="background-container">
      <ParticlesBg type="circle" color="#b5b5b5c2" num={5} bg={true} />
      <section className="section-1">
        <div className="image-container">
          <img src={profileImage} alt="Profile Img" />
        </div>
        <div className="main-heading">
          <h1>Hello World,</h1>
          <h1>I'm Jose Aguirre!</h1>
          <h1 className="heading">I am a<span></span></h1>
        </div>
      </section>
      <button className="dark-light-button" onClick={toggleTheme}>
        {isDark ? 'Switch to light mode' : 'Switch to dark mode'}
      </button>
    </div>
  )
}

export default Home;
