import './Projects.scss'
import { useState, useEffect } from 'react'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'

function Projects(props) {
  // create state to hold projects
  const [projects, setProjects] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  //create function to make api call
  const getProjectsData = async () => {
    try {
      //make api call and get response
      const response = await fetch('./projects.json')
      // turn response into javascript object
      const data = await response.json()
      // set the projects state to the data
      setProjects(data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const spanStyle = {
    padding: '5px',
    background: '#efefef',
    color: 'grey'
  }

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '480px'
  }
  const slideImages = [
    {
      url: 'https://cdn.discordapp.com/attachments/489903000966529024/1108050278797803600/Screen_Shot_2023-05-15_at_1.55.55_PM.png',
      caption: 'SkySight',
      bio: 'SkySight offers a seamless integration of non-live flight tracking and live weather data through a full-stack Django application. Designed with user experience in mind, it provides travelers and aviation enthusiasts with insightful data, ensuring they are always informed and prepared for their journeys.',
      link: 'https://skysight.herokuapp.com/',
      git: 'https://github.com/JoseAguirreCruz/SkySight-App/tree/master'
    },
    {
      url: 'https://cdn.discordapp.com/attachments/1104989771471798334/1104989806150299688/ViChat.png',
      caption: 'ViChat',
      bio: 'ViChat connects people through an intuitive full-stack web application, allowing users to effortlessly add contacts to their friend list and engage in video calls directly from their device. The application emphasizes user-friendly interactions, ensuring smooth and reliable communication among peers.',
      link: 'https://vichat-messaging-app.herokuapp.com/',
      git: 'https://github.com/sivaShilpa/Vichat_Project3'
    },
    {
      url: 'https://cdn.discordapp.com/attachments/1104989771471798334/1104989805881860136/taskmastermind.jpg',
      caption: 'TaskMastermind',
      bio: 'TaskMastermind is your personal task manager and tracker, built with the MEN (MongoDB, Express.js, Node.js) stack to streamline your daily planning. The application facilitates efficient task management, enabling users to prioritize, track, and manage their day-to-day activities with ease.',
      link: 'https://taskmastermind.herokuapp.com/home',
      git: 'https://github.com/Dhart1984/Project-2'
    },
    {
      url: 'https://cdn.discordapp.com/attachments/489903000966529024/1153765467199131720/Screenshot_2023-09-19_at_2.51.46_PM.png',
      caption: 'Royal Adventures PR',
      bio: 'Royal Adventures PR - Developed a responsive and user-friendly full stack website for a local company in Puerto Rico, utilizing express.js as the backend framework and MongoDB as the database, resulting in a 30% increase in website traffic. ',
      link: 'https://www.royaladventurespr.com/',
      git: 'https://github.com/JoseAguirreCruz/RoyalRentals'
    },
  ]

  // make an initial call for the data inside a useEffect, so it only happens once on component load
  useEffect(() => {
    getProjectsData()
  }, [])

  // define a function that will return the JSX needed once we get the data
  const loaded = () => {
    return (
      <>
        <div className="project-container">
          <h1 className="projects-h1">My Projects</h1>
          <div className="slide-container">
            <Slide>
            {slideImages.map((slideImage, index) => (
              <>
                <div key={index}>
                  <div
                    className="slide-image-container"
                    style={{ backgroundImage: `url(${slideImage.url})` }}
                  >
                    {/* <div className="slide-caption">{slideImage.caption}</div> */}
                  </div>
                </div>
                <div className="slide-text-container">
                  <div className="slide-caption">{slideImage.bio}</div>
                  <div className="buttons-container">
                    <a href={slideImage.link} target="_blank" rel="noopener noreferrer">
                      <button>View Project</button>
                    </a>
                    <a href={slideImage.git} target="_blank" rel="noopener noreferrer">
                      <button>View GitHub</button>
                    </a>
                  </div>
                </div>
              </>
            ))}
          </Slide>
          </div>
        </div>
      </>
    )
  }

  const loading = () => {
    return (
      <div className="all">
        <div className="container">
          <div className="top"></div>
          <div className="inner-oval">
            <div className="circle1"></div>
            <div className="circle2"></div>
            <div className="circle3"></div>
          </div>
        </div>
      </div>
    )
  }

  return <section>{isLoading ? loading() : loaded()}</section>
}

export default Projects
