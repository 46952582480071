import emailjs from 'emailjs-com';
import './Contact.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import ParticlesBg from "particles-bg";

function Contact() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_kum0n8f', 'template_oeutsq7', e.target, 'yJI96BcQjpQdtmZxl')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    e.target.reset();
  }

  return (
    <div className="background-container">
    <ParticlesBg type="circle" color="#b5b5b5c2" num={5} bg={true} />
    <form className="contact-form" onSubmit={sendEmail}>
      <h1 className='contact-title'>Contact Me</h1>
      <br />
      <input type="hidden" name="contact_number" />
      <label>Name</label>
      <input type="text" name="name" />
      <label>Email</label>
      <input type="email" name="email" />
      <label>Message</label>
      <textarea name="message" />
      <button type="submit" value="Send">Send</button>
      <div className='profile-links'>
        <a className="button-link" href={`${process.env.PUBLIC_URL}/my_resume.pdf`} download>Resume</a>
        <a className="button-link" href="https://www.linkedin.com/in/jose-aguirre-cruz/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
        <a className="button-link" href="https://github.com/JoseAguirreCruz" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub} /></a>
      </div>
    </form>
    </div>
  );
}

export default Contact;
